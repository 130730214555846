<template>
  <div class="profile-page">
    <div class="background-overlay"></div> <!-- 添加透明度覆盖层 -->
    <div class="profile-container">
      <div class="profile-header">
        <el-avatar :src="userInfo.avatar" class="avatar-image" @click="handleAvatarClick"></el-avatar>
        <el-button type="primary" @click="enableEdit" class="edit-button">编辑</el-button>
      </div>
      <div class="profile-content">
        <el-form :model="userInfo" label-width="100px">
          <el-form-item label="账号">
            <el-input v-model="userInfo.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="userInfo.nickname" :disabled="!isEditing"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="userInfo.email" disabled></el-input>
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker
              v-model="userInfo.birthday"
              type="date"
              placeholder="选择日期"
              :disabled="!isEditing"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="userInfo.sex" :disabled="!isEditing">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="个人介绍">
            <el-input
              type="textarea"
              v-model="userInfo.introduction"
              :disabled="!isEditing"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isEditing" class="form-item-center">
            <el-button type="primary" @click="saveChanges">保存</el-button>
            <el-button @click="cancelEdit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="file-list-container">
        <h2>我的文件列表</h2>
        <el-table :data="fileList" style="width: 100%">
          <el-table-column prop="fileName" label="文件名" width="180"></el-table-column>
          <el-table-column prop="createTime" label="上传时间" width="180"></el-table-column>
          <el-table-column prop="fileSizeDisplay" label="大小" width="100"></el-table-column>
          <el-table-column label="操作" width="120">
            <template v-slot="scope">
              <el-button @click="deleteFile(scope.row.uuid)" type="danger" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalFiles"
            :page-size="pageSize"
            @current-change="handlePageChange"
          />
        </div>
      </div>
    </div>
    <el-dialog title="修改头像" v-model="showAvatarDialog">
      <el-upload
        class="avatar-uploader"
        action="https://project.wuyi.life/user/avatar"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :headers="uploadHeaders"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  data() {
    return {
      userInfo: {
        username: '',
        nickname: '',
        email: '',
        birthday: '',
        introduction: '',
        sex: '',
        avatar: '',
      },
      isEditing: false,
      showAvatarDialog: false,
      imageUrl: '',
      fileList: [],
      totalFiles: 0,
      pageSize: 8,
      currentPage: 1
    };
  },
  created() {
    this.fetchUserInfo();
    this.fetchUserFiles();
  },
  computed: {
    uploadHeaders() {
      return {
        'token': localStorage.getItem('token')
      };
    }
  },
  methods: {
    fetchUserInfo() {
      fetch('https://project.wuyi.life/user/info', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
        },
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 0) {
            this.userInfo = data.data;
            this.imageUrl = this.userInfo.avatar;
          } else {
            this.$message.error('获取用户信息失败');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.$message.error('获取用户信息失败');
        });
    },
    fetchUserFiles() {
      fetch(`https://project.wuyi.life/file/myFiles?page=${this.currentPage}&page_size=${this.pageSize}`, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.fileList = data.data.files.map(file => {
              const fileSizeInKB = file.fileSize / 1024;
              const fileSizeDisplay = fileSizeInKB > 1024 ? (fileSizeInKB / 1024).toFixed(2) + ' MB' : fileSizeInKB.toFixed(2) + ' KB';
              return { ...file, fileSizeDisplay };
            });
            this.totalFiles = data.data.total;
          } else {
            this.$message.error('获取文件列表失败');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.$message.error('获取文件列表失败');
        });
    },
    deleteFile(uuid) {
      fetch(`https://project.wuyi.life/file/delete?uuid=${uuid}`, {
        method: 'POST',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.$message.success('文件删除成功');
            this.fetchUserFiles();
          } else {
            this.$message.error('文件删除失败');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.$message.error('文件删除失败');
        });
    },
    enableEdit() {
      this.isEditing = true;
    },
    saveChanges() {
      const sexValue = this.userInfo.sex === '男' ? 0 : 1;

      const birthday = this.userInfo.birthday;
      let formattedBirthday = '';

      if (birthday instanceof Date) {
        const year = birthday.getFullYear();
        const month = String(birthday.getMonth() + 1).padStart(2, '0');
        const day = String(birthday.getDate()).padStart(2, '0');
        formattedBirthday = `${year}-${month}-${day}`;
      } else {
        formattedBirthday = birthday;
      }

      fetch('https://project.wuyi.life/user/info', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        },
        credentials: 'include',
        body: JSON.stringify({
          nickname: this.userInfo.nickname,
          birthday: formattedBirthday,
          introduction: this.userInfo.introduction,
          sex: sexValue,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 0) {
            this.$message.success('保存成功');
            this.isEditing = false;
            this.fetchUserInfo();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.$message.error('保存失败');
        });
    },
    cancelEdit() {
      this.isEditing = false;
      this.fetchUserInfo();
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchUserFiles();
    },
    handleAvatarClick() {
      this.showAvatarDialog = true;
    },
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.imageUrl = res.data;
        this.userInfo.avatar = res.data;
        this.$emit('update-avatar', res.data);
        this.$message.success('头像上传成功');
        this.showAvatarDialog = false;
        this.reloadPage();
      } else {
        this.$message.error('头像上传失败');
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped>
.profile-page {
  position: relative;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3; /* 调整透明度 */
  z-index: 1;
}

.profile-container {
  max-width: 800px;
  margin: 80px auto;
  padding: 20px;
  background: #fff;  /* 背景为白色 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  position: relative; /* 确保背景覆盖层不会影响到内容 */
  z-index: 2; /* 确保其在背景覆盖层之上 */
}

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.avatar-image {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-bottom: 10px;
}

.edit-button {
  background-color: #409eff;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background-color: #66b1ff;
  transform: scale(1.05);
}

.profile-content {
  width: 100%;
  text-align: left;
}

.el-form-item {
  margin-bottom: 20px;
}

.form-item-center {
  display: flex;
  justify-content: center;
}

.el-input, .el-button, .el-date-picker {
  transition: all 0.3s ease;
}

.el-input:focus, .el-button:focus, .el-date-picker:focus {
  box-shadow: 0 0 10px rgba(81, 203, 238, 1);
  border-color: rgba(81, 203, 238, 1);
}

.file-list-container {
  margin-top: 40px;
  padding-left: 20px;
}

.file-list-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
}

.avatar {
  width: 100%;
  height: 100%;
  display: block;
}

.el-textarea__inner {
  resize: none;
}
</style>
