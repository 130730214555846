<template>
  <div class="upload-page">
    <div class="background-overlay"></div> <!-- 添加透明度覆盖层 -->
    <div class="upload-container">
      <el-form ref="uploadForm" :model="uploadForm" label-width="100px">
        <el-form-item label="文件">
          <el-upload
            class="upload-file"
            action="https://project.wuyi.life/file/upload"
            :show-file-list="false"
            :on-success="handleFileUploadSuccess"
            :before-upload="beforeFileUpload"
            :headers="uploadHeaders"
          >
            <el-button type="primary">点击上传文件</el-button>
          </el-upload>
          <span>{{ uploadForm.fileName }}</span>
        </el-form-item>
        <el-form-item label="文件封面">
          <el-upload
            class="upload-cover"
            action="https://project.wuyi.life/file/uploadCover"
            :show-file-list="false"
            :on-success="handleCoverUploadSuccess"
            :before-upload="beforeCoverUpload"
            :headers="uploadHeaders"
          >
            <el-button type="primary">点击上传封面</el-button>
          </el-upload>
          <span>{{ uploadForm.coverName }}</span>
        </el-form-item>
        <el-form-item label="文件名称">
          <el-input 
            v-model="uploadForm.displayFileName" 
            @input="updateFileName" 
            :disabled="!isEditingFileName"
          ></el-input>
          <span class="file-extension">{{ fileExtension }}</span>
        </el-form-item>
        <el-form-item label="简介">
          <el-input 
            type="textarea" 
            v-model="uploadForm.introduction" 
            :maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="uploadForm.categoryId" placeholder="请选择分类">
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUpload">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadForm: {
        fileName: '',
        displayFileName: '',
        coverName: '',
        fileUrl: '',
        imgUrl: '',
        introduction: '',
        categoryId: null
      },
      fileExtension: '',
      categories: [],
      isEditingFileName: false
    }
  },
  computed: {
    uploadHeaders() {
      return {
        'token': localStorage.getItem('token')
      };
    }
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      fetch('https://project.wuyi.life/file/categories', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.categories = data.data;
          } else {
            this.$message.error('获取分类信息失败');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.$message.error('获取分类信息失败');
        });
    },
    handleFileUploadSuccess(res) {
      if (res.code === 0) {
        this.uploadForm.fileUrl = res.data.fileUrl;
        this.uploadForm.fileName = res.data.fileName;
        this.fileExtension = this.getFileExtension(res.data.fileName);
        this.uploadForm.displayFileName = this.getFileNameWithoutExtension(res.data.fileName);
        this.$message.success('文件上传成功');
        this.isEditingFileName = true;
      } else {
        this.$message.error('文件上传失败');
      }
    },
    handleCoverUploadSuccess(res) {
      if (res.code === 0) {
        this.uploadForm.imgUrl = res.data.fileUrl;
        this.uploadForm.coverName = res.data.fileName;
        this.$message.success('封面上传成功');
      } else {
        this.$message.error('封面上传失败');
      }
    },
    beforeFileUpload(file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        this.$message.error('上传文件大小不能超过 100MB!');
      }
      return isLt100M;
    },
    beforeCoverUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传封面大小不能超过 10MB!');
      }
      return isLt10M;
    },
    updateFileName() {
      this.uploadForm.fileName = `${this.uploadForm.displayFileName}${this.fileExtension}`;
    },
    getFileExtension(fileName) {
      return fileName.slice(fileName.lastIndexOf('.'));
    },
    getFileNameWithoutExtension(fileName) {
      return fileName.slice(0, fileName.lastIndexOf('.'));
    },
    submitUpload() {
      fetch('https://project.wuyi.life/file/up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        },
        credentials: 'include',
        body: JSON.stringify(this.uploadForm)
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.$message.success('文件详细信息上传成功');
            this.$router.push('/'); // 上传成功后跳转回首页
          } else {
            this.$message.error('文件详细信息上传失败');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.$message.error('文件详细信息上传失败');
        });
    }
  }
}
</script>

<style scoped>
.upload-page {
  position: relative;
  min-height: 100vh;
  padding: 20px;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3; /* 调整透明度 */
  z-index: 1;
}

.upload-container {
  max-width: 600px;
  margin: 80px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  z-index: 2; /* 确保其在背景覆盖层之上 */
}

.upload-file,
.upload-cover {
  display: flex;
  align-items: center;
}

.upload-file .el-button,
.upload-cover .el-button {
  margin-right: 10px;
}

.file-extension {
  margin-left: 10px;
  line-height: 32px; /* Adjust to align with input field */
}

.upload-container .el-form-item {
  margin-bottom: 20px;
}

.upload-container .el-input {
  width: 100%;
}
</style>
