import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/styles.css'
import * as ElIconModules from '@element-plus/icons-vue'
import { quillEditor } from 'vue3-quill' // Correct import
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 全局注册 EditorToolbar, EditorEditable 两个组件

const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(quillEditor) // Register the quillEditor

for (const [key, component] of Object.entries(ElIconModules)) {
    app.component(key, component)
}

app.mount('#app')
