<template>
  <div class="search-results">
    <div class="background-overlay"></div> <!-- 添加透明度覆盖层 -->
    <el-row :gutter="20" class="file-list">
      <template v-if="files.length > 0">
        <el-col :span="6" v-for="file in files" :key="file.uuid" :class="{'mobile-col': isMobile}">
          <div class="flip-card-wrapper">
            <div class="flip-card" @click="downloadFile(file.uuid)">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img :src="file.fileImg" class="file-image" />
                  <h3 class="file-title">{{ file.fileName }}</h3>
                </div>
                <div class="flip-card-back">
                  <h3>{{ file.fileName }}</h3>
                  <p>{{ file.introduction }}</p>
                  <div class="file-meta">
                    <span>作者: {{ file.authorName }}</span>
                    <span>大小: {{ file.fileSizeDisplay }}</span>
                    <span>创建时间: {{ file.createTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <el-progress
              v-if="file.downloading"
              class="custom-progress"
              :percentage="file.downloadProgress"
              status="active"
              :text-inside="true"
              stroke-width="8"
            />
          </div>
        </el-col>
      </template>
      <template v-else>
        <div class="no-results">没有搜索到任何文件哦~</div>
      </template>
    </el-row>
    <div class="pagination" v-if="files.length > 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalFiles"
        :page-size="pageSize"
        @current-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResults',
  props: {
    files: {
      type: Array,
      required: true
    },
    totalFiles: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    handlePageChange(page) {
      this.$emit('page-change', page);
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    checkLoginStatus() {
      return fetch('https://project.wuyi.life/user/islogin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        return data.data;
      })
      .catch(error => {
        console.error('Error checking login status:', error);
        return false;
      });
    },
    downloadFile(uuid) {
      const file = this.files.find(f => f.uuid === uuid);
      if (file) {
        this.checkLoginStatus().then(isLoggedIn => {
          if (!isLoggedIn) {
            this.$router.push('/user/login'); // 跳转到登录页面
            return;
          }

          file.downloading = true;
          fetch(`https://project.wuyi.life/file/download?uuid=${uuid}`, {
            method: 'GET',
            headers: {
              'token': localStorage.getItem('token'),
              'Content-Type': 'application/json'
            }
          }).then(response => {
            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
            let receivedLength = 0;
            let chunks = [];

            return reader.read().then(function processText({ done, value }) {
              if (done) {
                const blob = new Blob(chunks);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.fileName;
                link.click();
                file.downloading = false;
                return;
              }

              chunks.push(value);
              receivedLength += value.length;
              file.downloadProgress = Math.round((receivedLength / contentLength) * 100);
              return reader.read().then(processText);
            });
          }).catch(error => {
            console.error('Error:', error);
            file.downloading = false;
            this.$message.error('下载失败');
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.search-results {
  padding: 20px;
  min-height: calc(100vh - 60px); /* 调整背景区域大小 */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3; /* 调整透明度 */
  z-index: 2;
}

.file-list {
  width: 100%;
  min-width: 1100px;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px; /* 调整卡片距离导航栏的距离 */
}

.flip-card-wrapper {
  position: relative;
  width: 250px;
  height: 270px; /* 调整高度以容纳进度条 */
  margin: 10px; /* 添加间距以防止重叠 */
  z-index: 999;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px;
  /* 3D视图 */
  cursor: pointer;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-sizing: border-box;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  white-space: pre-wrap; /* 确保长单词和段落能换行 */
  word-break: break-word; /* 确保长单词能换行 */
  padding: 15px;
}

.file-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.file-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.file-meta {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  /* 调整文字大小 */
  white-space: pre-wrap; /* 确保长单词和段落能换行 */
  word-break: break-word; /* 确保长单词能换行 */
}

.el-progress {
  position: absolute;
  bottom: -20px;
  /* 调整进度条位置，使其更显眼 */
  left: 0;
  width: 100%;
}

.custom-progress .el-progress-bar__outer {
  background-color: rgba(0, 0, 0, 0.1);
  /* 设置背景色为浅灰色 */
}

.custom-progress .el-progress-bar__inner {
  background-color: #409eff;
  /* 设置进度条颜色为蓝色 */
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* 增加分页按钮与文件列表的间距 */
  width: 100%;
  z-index: 999;
}

.no-results {
  font-size: 18px;
  color: #666;
  margin-top: 20px;
  text-align: center; /* 居中对齐文本 */
  flex: 1; /* 使其占据剩余空间 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  z-index: 999;
}

@media (max-width: 1200px) {
  .file-list {
    flex-direction: column;
    align-items: center;
  }

  .mobile-col {
    width: 100% !important;
  }

  .flip-card-wrapper {
    margin-bottom: 20px;
  }
}
</style>
