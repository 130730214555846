<template>
  <div class="new-home-page">
    <div class="background-overlay"></div> <!-- 添加透明度覆盖层 -->
    <div class="navbar">
      <h2 class="navbar-title">文件分类</h2>
    </div>
    <div class="categories">
      <el-button
        v-for="category in categories"
        :key="category.id"
        @click="filterByCategory(category.id)"
        :class="{ active: selectedCategory === category.id }"
        plain
        class="category-button"
      >
        {{ category.name }}
      </el-button>
    </div>
    <div class="file-list-container">
      <div v-for="file in files" :key="file.uuid" class="flip-card-wrapper">
        <div class="flip-card" @click="downloadFile(file.uuid, $event)">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img :src="file.fileImg" class="file-image" />
              <h3 class="file-title">{{ file.fileName }}</h3>
            </div>
            <div class="flip-card-back">
              <h3>{{ file.fileName }}</h3>
              <p>{{ file.introduction }}</p>
              <div class="file-meta">
                <span>作者: {{ file.authorName }}</span>
                <span>大小:{{ file.fileSizeDisplay }}</span>
                <span>创建时间: {{ file.createTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-progress
          v-if="file.downloading"
          class="custom-progress"
          :percentage="file.downloadProgress"
          status="active"
          :text-inside="true"
          stroke-width="8"
        />
      </div>
      <div v-if="files.length === 0" class="no-files">
        没有搜索到任何文件哦~
      </div>
      <div class="pagination" v-if="files.length">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalFiles"
          :page-size="pageSize"
          @current-change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewHomePage',
  data() {
    return {
      categories: [],
      files: [],
      totalFiles: 0,
      pageSize: 8,
      currentPage: 1,
      selectedCategory: null
    };
  },
  created() {
    console.log('NewHomePage created');
    this.fetchCategories();
  },
  methods: {
    checkLoginStatus() {
      return fetch('https://project.wuyi.life/user/islogin', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      })
      .then(response => response.json())
      .then(data => {
        return data.data;
      })
      .catch(error => {
        console.error('Error checking login status:', error);
        return false;
      });
    },
    fetchCategories() {
      console.log('Fetching categories...');
      fetch('https://project.wuyi.life/file/categories', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('Fetch categories data:', data);
          if (data.code === 0) {
            this.categories = data.data;
            if (this.categories.length > 0) {
              this.filterByCategory(this.categories[0].id);
            }
          } else {
            // this.$message.error('获取分类信息失败')
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // this.$message.error('获取分类信息失败')
        });
    },
    fetchFilesByCategory(categoryId, page = 1) {
      console.log('Fetching files by category:', categoryId);
      fetch(`https://project.wuyi.life/file/list?category_id=${categoryId}&page=${page}&page_size=${this.pageSize}`, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('Fetch files by category data:', data);
          if (data.code === 0) {
            this.files = data.data.files.map(file => {
              const fileSizeInKB = file.fileSize / 1024;
              const fileSizeDisplay = fileSizeInKB > 1024 ? (fileSizeInKB / 1024).toFixed(2) + ' MB' : fileSizeInKB.toFixed(2) + ' KB';
              return { ...file, downloading: false, downloadProgress: 0, fileSizeDisplay };
            });
            this.totalFiles = data.data.total;
          } else {
            // this.$message.error('获取文件列表失败')
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // this.$message.error('获取文件列表失败')
        });
    },
    filterByCategory(categoryId) {
      console.log('Filtering files by category:', categoryId);
      this.selectedCategory = categoryId;
      this.fetchFilesByCategory(categoryId, this.currentPage);
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchFilesByCategory(this.selectedCategory, page);
    },
    downloadFile(uuid) {
      const file = this.files.find(f => f.uuid === uuid);
      if (file) {
        this.checkLoginStatus().then(isLoggedIn => {
          if (!isLoggedIn) {
            this.$router.push('/user/login'); // 跳转到登录页面
            return;
          }

          file.downloading = true;
          fetch(`https://project.wuyi.life/file/download?uuid=${uuid}`, {
            method: 'GET',
            headers: {
              'token': localStorage.getItem('token'),
              'Content-Type': 'application/json'
            }
          }).then(response => {
            const reader = response.body.getReader();
            const contentLength = +response.headers.get('Content-Length');
            let receivedLength = 0;
            let chunks = [];

            return reader.read().then(function processText({ done, value }) {
              if (done) {
                const blob = new Blob(chunks);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.fileName;
                link.click();
                file.downloading = false;
                return;
              }

              chunks.push(value);
              receivedLength += value.length;
              file.downloadProgress = Math.round((receivedLength / contentLength) * 100);
              return reader.read().then(processText);
            });
          }).catch(error => {
            console.error('Error:', error);
            file.downloading = false;
            this.$message.error('下载失败');
          });
        });
      }
    }
  }
}
</script>

<style scoped>
.new-home-page {
  position: relative;
  min-height: 100vh;
  padding: 20px;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3; /* 调整透明度 */
  z-index: 1;
}

.navbar {
  background-color: #0B6CB8;
  padding: 10px;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center;
  z-index: 999;
  position: relative;
}

.navbar-title {
  color: #fff;
  margin: 0;
  font-size: 24px;
}

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  z-index: 999;
  position: relative;
}

.categories .el-button {
  width: 250px; /* 固定宽度 */
  height: 50px; /* 固定高度 */
  margin: 5px 0;
  font-size: 18px; /* 增加字体大小 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories .el-button:hover,
.categories .el-button.active {
  background-color: #ffffff;  /* 白色背景 */
  color: #409EFF;  /* 蓝色字体 */
  border-color: #409EFF;  /* 蓝色边框 */
}

.file-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
}

.flip-card-wrapper {
  width: calc(100% - 20px);
  max-width: 300px;  /* 增加最大宽度 */
  margin: 10px;
}

.flip-card {
  width: 100%;
  height: 300px;  /* 增加高度 */
  perspective: 1000px;
  cursor: pointer;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
  text-align: center;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  text-align: center;
  white-space: pre-wrap; /* 确保长单词和段落能换行 */
  word-break: break-word; /* 确保长单词能换行 */
  padding: 15px;  /* 增加内边距 */
}

.file-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.file-title {
  font-size: 18px;  /* 增加字体大小 */
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

.file-meta {
  display: flex;
  flex-direction: column;
  font-size: 14px;  /* 增加字体大小 */
  text-align: center;
}

.el-progress {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
}

.custom-progress .el-progress-bar__outer {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-progress .el-progress-bar__inner {
  background-color: #409eff;
}

.pagination {
  margin-top: 20px;
  align-self: center;
  z-index: 2;
  position: relative;
}

@media (min-width: 481px) {
  .flip-card-wrapper {
    width: 300px;  /* 增加宽度 */
  }
}


</style>
