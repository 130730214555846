import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import UserProfile from '../views/UserProfile.vue'
import SearchResults from '../views/SearchResults.vue'
import UploadFile from '../views/UploadFile.vue'
import MobileHomePage from '../views/MobileHomePage.vue';
import MessageBoard from '../views/MessageBoard.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/file/upload',
    name: 'UploadFile',
    component: UploadFile
  },
  {
    path: '/new-home',
    name: 'MobileHomePage',
    component: MobileHomePage
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
    props: (route) => ({ files: route.query.files })
  },
  {
    path: '/message-board',
    name: 'MessageBoard',
    component: MessageBoard
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
