<template>
  <div id="app">
    <el-container>
      <el-header v-if="showHeader" class="header">
        <div class="header-content">
          <div class="logo" @click="goHome">文件资源共享平台</div>
          <div class="search-container">
            <el-input
              v-model="searchQuery"
              placeholder="搜索文件..."
              class="search-bar"
              @keyup.enter="searchFiles"
              clearable
            ></el-input>
            <el-button class="search-button" @click="searchFiles">
              搜索
            </el-button>
            <el-button class="oj-button" @click="openOJ">OJ题解</el-button>
          </div>
          <div class="auth-container">
            <div v-if="!isLoggedIn" class="auth-buttons">
              <el-button type="primary" class="login-button" @click="goLogin">登录</el-button>
            </div>
            <div v-else class="user-info">
              <span class="user-name" @click="goProfile">{{ userInfo.username }}</span>
              <el-dropdown>
                <template #default>
                  <span class="el-dropdown-link">
                    <el-avatar :src="userInfo.avatar" class="avatar-image" @click="handleAvatarClick"></el-avatar>
                  </span>
                </template>
                <template v-slot:dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="goProfile">个人中心</el-dropdown-item>
                    <el-dropdown-item @click="goUpload">上传文件</el-dropdown-item>
                    <el-dropdown-item @click="goMessageBoard">留言板</el-dropdown-item>
                    <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view 
          :files="searchResults" 
          :totalFiles="totalFiles" 
          :pageSize="pageSize" 
          @page-change="handlePageChange" 
          @update-avatar="updateAvatar"
        />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false,
      userInfo: {},
      showHeader: true,
      searchQuery: '',
      searchResults: [],
      totalFiles: 0,
      pageSize: 8,
      currentPage: 1
    }
  },
  created() {
    this.checkLoginStatus()
    this.$router.beforeEach((to, from, next) => {
      this.showHeader = to.path !== '/user/login' && to.path !== '/user/register'
      next()
    })
    this.$router.afterEach(() => {
      if (this.showHeader) {
        this.checkLoginStatus()
      }
    })
  },
  methods: {
    checkLoginStatus() {
      fetch('https://project.wuyi.life/user/islogin', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.code === 0 && data.data) {
          this.isLoggedIn = true
          this.fetchUserInfo()
        } else {
          this.isLoggedIn = false
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
    },
    fetchUserInfo() {
      fetch('https://project.wuyi.life/user/info', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          this.userInfo = data.data
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
    },
    searchFiles() {
      fetch(`https://project.wuyi.life/file/search?keyword=${this.searchQuery}&page=${this.currentPage}&page_size=${this.pageSize}`, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          console.log('Search results:', data.data.files)
          this.searchResults = data.data.files.map(file => {
            const fileSizeInKB = file.fileSize / 1024
            const fileSizeDisplay = fileSizeInKB > 1024 ? (fileSizeInKB / 1024).toFixed(2) + ' MB' : fileSizeInKB.toFixed(2) + ' KB'
            return { ...file, fileSizeDisplay }
          })
          this.totalFiles = data.data.total
          this.$router.push({ path: '/search', query: { keyword: this.searchQuery } })
        } else {
          this.$message.error('搜索失败')
        }
      })
      .catch(error => {
        console.error('Error:', error)
        this.$message.error('搜索失败')
      })
    },
    handlePageChange(page) {
      this.currentPage = page
      this.searchFiles()
    },
    goLogin() {
      this.$router.push('/user/login')
    },
    goProfile() {
      this.$router.push('/user/profile') // 假设有个人中心页面
    },
    goUpload() {
      this.$router.push('/file/upload') // 假设有上传文件页面
    },
    goMessageBoard() {
      this.$router.push('/message-board') // 假设有留言板页面
    },
    logout() {
      fetch('https://project.wuyi.life/user/logout', {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          this.$message.success('退出成功')
          this.isLoggedIn = false
          localStorage.removeItem('token')
          this.$router.push('/')
        } else {
          this.$message.error('退出失败')
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
    },
    goHome() {
      this.$router.push('/')
    },
    openOJ() {
      window.open('https://www.nenuacm.top', '_blank');
    },
    updateAvatar(newAvatar) {
      this.userInfo.avatar = newAvatar;
    }
  }
}
</script>

<style scoped>
.el-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #e0f7fa, #ffffff, #e0f7fa); /* 渐变背景色 */
  padding: 0 20px;
  transition: background-color 0.5s;
  font-family: 'SimHei', sans-serif;
  font-weight: bold;
  z-index: 1000;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  border-bottom: 1px solid #e0e0e0; /* 添加分割线 */
}

.el-header::-webkit-scrollbar {
  width: 0;  /* 仅用于隐藏滚动条 */
  background: transparent;  /* 使滚动条背景透明 */
}

.el-header {
  -ms-overflow-style: none;  /* 适用于 IE 和 Edge */
  scrollbar-width: none;  /* 适用于 Firefox */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.el-header:hover {
  background-color: #ffffff;
}

.logo {
  font-size: 24px;
  color: rgb(0, 0, 0);
  line-height: 60px;
  padding-left: 20px;
  cursor: pointer;
  transition: color 0.3s; /* 去掉 transform */
}

.logo:hover {
  color: #409eff;
}

.search-container {
  display: flex;
  align-items: center;
  margin: 0 auto; /* 添加这行代码让搜索栏居中 */
}

.search-bar {
  width: 300px;
}

.search-button {
  margin-left: 10px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  line-height: 1; /* 确保文本垂直居中 */
}

.search-button i {
  margin-right: 5px; /* 调整图标和文本之间的间距 */
}

.search-button:hover {
  background-color: #ffffff;
  color: #409eff;
  transform: scale(1.1);
}

.nav-container {
  margin-right: auto;
  margin-left: 20px;
}

.oj-button {
  background-color: #409eff;
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.oj-button:hover {
  background-color: #79BBFF;
  color: white;
  transform: scale(1.1);
}

.auth-container {
  display: flex;
  align-items: center;
}

.auth-buttons {
  margin-left: auto;
}

.login-button {
  transition: background-color 0.3s, transform 0.3s;
}

.login-button:hover {
  background-color: #ffffff;
  color: #409eff;
  transform: scale(1.1);
}

.user-info {
  display: flex;
  align-items: center;
}

.user-name {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(0, 0, 0);
  margin-right: 15px;
  cursor: pointer;
  transition: color 0.3s, text-decoration 0.3s;
}

.user-name:hover{
  color: #409eff;
  margin-right: 15px;
}

.avatar-image {
  width: 40px;
  height: 40px;
  transition: transform 0.3s;
}

.el-dropdown-link:hover .avatar-image {
  transform: rotate(360deg);
}

.el-main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  min-height: 100vh; /* 背景颜色覆盖整个屏幕 */
}

@media (max-width: 768px) {
  .logo {
    font-size: 14px;
    margin-left: -20px;
  }

  .search-bar {
    margin-left: 10px;
    width: 90px;
  }

  .search-button {
    margin-left: 5px;
    font-size: 14px;
  }

  .user-name {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    transition: color 0.3s, text-decoration 0.3s;
  }

  .avatar-image {
    width: 30px;
    height: 30px;
  }
}
</style>
