<template>
    <div class="message-board-container">
        <div class="background"></div>
        <div class="message-board-content">
            <div class="messages">
                <div v-for="message in messages" :key="message.id" class="message-item">
                    <el-avatar :src="message.avatar" class="message-avatar"></el-avatar>
                    <div class="message-content">
                        <div class="message-text" v-html="message.content"></div>
                        <div class="message-user">
                            <span class="nickname">{{ message.nickname }}</span>
                            <span class="username">({{ message.username }})</span>
                            <span class="create-time">{{ message.createTime }}</span>
                        </div>
                    </div>
                    <el-button
                        v-if="message.status"
                        type="danger"
                        size="small"
                        @click="deleteMessage(message.id)"
                        class="delete-button"
                    >
                        <el-icon>
                            <Delete />
                        </el-icon>
                        删除
                    </el-button>
                </div>
            </div>
            <div class="message-input">
                <div style="border: 1px solid #ccc; width: 100%;">
                    <Toolbar
                        style="border-bottom: 1px solid #ccc"
                        :editor="editorRef"
                        :defaultConfig="toolbarConfig"
                    />
                    <Editor
                        style="height: 200px; overflow-y: hidden;"
                        v-model="valueHtml"
                        :defaultConfig="editorConfig"
                        @onCreated="handleCreated"
                        @onChange="handleChange"
                        @onFocus="handleFocus"
                        @onBlur="handleBlur"
                    />
                </div>
                <el-button
                    type="primary"
                    @click="sendMessage"
                    class="send-button"
                    :disabled="plainTextLength > 200"
                >
                    发送
                </el-button>
                <div class="char-count">字符数: {{ plainTextLength }}/200</div>
            </div>
            <el-pagination
                v-if="totalMessages > 0"
                background
                layout="prev, pager, next"
                :total="totalMessages"
                :page-size="pageSize"
                @current-change="fetchMessages"
                class="pagination"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ElButton, ElAvatar, ElPagination, ElIcon } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'
import '@wangeditor/editor/dist/css/style.css'

export default {
    components: {
        ElButton,
        ElAvatar,
        ElPagination,
        ElIcon,
        Delete,
        Editor,
        Toolbar
    },
    setup() {
        const editorRef = shallowRef()
        const messages = ref([])
        const totalMessages = ref(0)
        const pageSize = ref(10)
        const currentPage = ref(1)
        const valueHtml = ref('') // 初始值为空字符串
        const plainTextLength = ref(0) // 用于记录纯文本的长度

        const toolbarConfig = {
            toolbarKeys: [
                'bold', 'italic', 'through', 'underline', 'bulletedList',
                'numberedList', 'color', 'fontSize', 'lineHeight',
                'delIndent', 'indent', 'justifyCenter',
                'justifyJustify', 'justifyLeft', 'justifyRight', 'undo', 'redo',
                'clearStyle'
            ]
        }

        const editorConfig = { 
            placeholder: '请输入内容...',
            MENU_CONF: {}
        }

        const handleFocus = (editor) => {
            try {
                if (editor.getHtml() === '<p>请输入内容...</p>') {
                    editor.clear(); // 清空内容
                }
            } catch (error) {
                console.log('捕获的错误: ', error.message);
            }
        };

        const handleBlur = (editor) => {
            try {
                const html = editor.getHtml();
                if (html === '<p><br></p>' || html.trim() === '') {
                    editor.setHtml(''); // 确保内容为空
                    valueHtml.value = ''; // 设置为真正的空字符串
                }
            } catch (error) {
                console.log('捕获的错误: ', error.message);
            }
        };

        onMounted(() => {
            fetchMessages(currentPage.value)
        })

        onBeforeUnmount(() => {
            const editor = editorRef.value
            if (editor == null) return
            editor.destroy()
        })

        const handleCreated = (editor) => {
            editorRef.value = editor
        }

        const handleChange = (editor) => {
            try {
                const html = editor.getHtml();
                
                // 计算纯文本的长度
                const plainText = html.replace(/<\/?[^>]+(>|$)/g, "").trim();
                plainTextLength.value = plainText.length;

                // 保留HTML数据供后端使用
                valueHtml.value = html;

                // 检查字符长度是否超过200
                if (plainTextLength.value > 200) {
                    console.error('字符数不能超过200');
                }
            } catch (error) {
                console.error('Error during handleChange:', error);
            }
        };

        const fetchMessages = (page) => {
            currentPage.value = page
            fetch(`https://project.wuyi.life/message?page=${currentPage.value}&pageSize=${pageSize.value}`, {
                method: 'GET',
                headers: {
                    'token': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.code === 0) {
                    messages.value = data.data.messages
                    totalMessages.value = data.data.total
                } else {
                    console.error('获取消息失败')
                }
            })
            .catch(error => {
                console.error('获取消息失败', error)
            })
        }

        const sendMessage = () => {
            const textContent = valueHtml.value.replace(/<[^>]*>?/gm, ''); // 去除 HTML 标签
            if (textContent.length < 5) {
                console.error('消息内容不能少于5个字符')
                return
            }
            fetch('https://project.wuyi.life/message', {
                method: 'POST',
                headers: {
                    'token': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ content: valueHtml.value })
            })
            .then(response => response.json())
            .then(data => {
                if (data.code === 0) {
                    console.log('消息发送成功')
                    valueHtml.value = ''
                    plainTextLength.value = 0; // 重置纯文本长度
                    fetchMessages(currentPage.value)
                } else {
                    console.error('消息发送失败')
                }
            })
            .catch(error => {
                console.error('消息发送失败', error)
            })
        }

        const deleteMessage = (messageId) => {
            fetch(`https://project.wuyi.life/message/${messageId}`, {
                method: 'POST',
                headers: {
                    'token': localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.code === 0) {
                    console.log('消息删除成功')
                    fetchMessages(currentPage.value)
                } else {
                    console.error('消息删除失败')
                }
            })
            .catch(error => {
                console.error('消息删除失败', error)
            })
        }

        return {
            editorRef,
            valueHtml,
            plainTextLength,
            messages,
            totalMessages,
            pageSize,
            currentPage,
            toolbarConfig,
            editorConfig,
            handleCreated,
            handleChange,
            handleFocus,
            handleBlur,
            sendMessage,
            deleteMessage,
            fetchMessages
        }
    }
}
</script>



<style scoped>
.message-board-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    padding-top: 80px;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.3;
    z-index: 1;
}

.message-board-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1400px;
    padding: 20px;
    white-space: pre-wrap;
    word-break: break-word;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
}

.message-item {
    display: flex;
    align-items: center;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    width: 90%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-avatar {
    width: 60px;
    height: 60px;
    margin-right: 30px;
}

.message-content {
    flex: 1;
}

.message-text {
    font-size: 18px;
    margin-bottom: 15px;
}

.message-user {
    font-size: 16px;
    color: #888;
}

.nickname {
    font-weight: bold;
}

.username {
    font-style: italic;
    margin-left: 5px;
}

.create-time {
    font-size: 14px;
    color: #aaa;
    margin-left: 10px;
}

.message-input {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.send-button {
    align-self: flex-end;
}

.delete-button {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
</style>
