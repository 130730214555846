<template>
  <div class="auth-container">
    <div class="auth-form-wrapper">
      <h2 class="form-title">注册</h2>
      <el-form :model="registerForm" label-width="80px" class="auth-form">
        <el-form-item label="账号">
          <el-input v-model="registerForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="registerForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="registerForm.email"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-row :gutter="20">
            <el-col :span="16">
              <el-input v-model="registerForm.verificationCode"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button @click="sendVerificationCode" :disabled="timer > 0" class="verification-button">
                {{ timer > 0 ? `${timer}s` : '发送验证码' }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="form-item-center">
          <el-button type="primary" @click="handleRegister" class="register-button">注册</el-button>
        </el-form-item>
        <el-form-item class="form-item-center">
          <el-link @click="goLogin">已有账号？登录</el-link>
        </el-form-item>
      </el-form>
      <el-button type="default" @click="goHome" class="home-button">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterComponent',
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        email: '',
        verificationCode: ''
      },
      timer: 0
    }
  },
  methods: {
    handleRegister() {
      if (!this.validateForm()) return;

      console.log('Register form submitted:', this.registerForm);

      fetch('https://project.wuyi.life/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.registerForm.username,
          password: this.registerForm.password,
          email: this.registerForm.email,
          code: this.registerForm.verificationCode
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.$message.success('注册成功')
            localStorage.setItem('token', data.data.token)
            this.$router.push('/')
          } else {
            this.$message.error(data.msg || '注册失败')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          this.$message.error('注册失败')
        })
    },
    validateForm() {
      if (!this.registerForm.username) {
        this.$message.error('用户名不能为空')
        return false
      }
      if (this.registerForm.username.length < 4 || this.registerForm.username.length > 20) {
        this.$message.error('用户名长度应在4到20之间')
        return false
      }
      if (!this.registerForm.password) {
        this.$message.error('密码不能为空')
        return false
      }
      if (this.registerForm.password.length < 6 || this.registerForm.password.length > 20) {
        this.$message.error('密码长度应在6到20之间')
        return false
      }
      if (!this.registerForm.email) {
        this.$message.error('邮箱不能为空')
        return false
      }
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      if (!emailPattern.test(this.registerForm.email)) {
        this.$message.error('请输入有效的邮箱地址')
        return false
      }
      if (!this.registerForm.verificationCode) {
        this.$message.error('验证码不能为空')
        return false
      }
      return true
    },
    sendVerificationCode() {
      if (this.timer > 0) return

      if (!this.registerForm.email) {
        this.$message.error('请输入邮箱地址')
        return
      }

      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      if (!emailPattern.test(this.registerForm.email)) {
        this.$message.error('请输入有效的邮箱地址')
        return
      }

      console.log('Sending verification code to:', this.registerForm.email)

      fetch('https://project.wuyi.life/user/code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: this.registerForm.email })
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.$message.success('验证码已发送')
            this.startTimer()
          } else {
            this.$message.error(data.msg || '发送验证码失败')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          this.$message.error('发送验证码失败')
        })
    },
    startTimer() {
      this.timer = 60
      const interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer -= 1
        } else {
          clearInterval(interval)
        }
      }, 1000)
    },
    goLogin() {
      this.$router.push('/user/login')
    },
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
}

.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  animation: fadeInUp 0.5s;
}

.auth-form {
  width: 100%;
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-input, .el-button {
  transition: all 0.3s ease;
}

.el-input:focus, .el-button:focus {
  box-shadow: 0 0 10px rgba(81, 203, 238, 1);
  border-color: rgba(81, 203, 238, 1);
}

.register-button {
  background-color: #409eff;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.register-button:hover {
  background-color: #66b1ff;
  transform: scale(1.05);
}

.verification-button {
  background-color: #409eff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
}

.verification-button:disabled {
  background-color: #c0c4cc;
  cursor: not-allowed;
}

.verification-button:hover:enabled {
  background-color: #66b1ff;
  transform: scale(1.05);
}

.form-item-center {
  display: flex;
  justify-content: center;
}

.home-button {
  margin-top: 20px;
  background-color: #67c23a;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #85d97a;
  transform: scale(1.05);
}
</style>
