<template>
  <div class="home-page">
    <div class="background-overlay"></div> <!-- 添加透明度覆盖层 -->
    <div class="categories">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#0B6CB8"
        text-color="#fff"
        active-text-color="#ffffff"
        :default-active="selectedCategory?.toString()"
      >
        <el-menu-item
          v-for="category in categories"
          :key="category.id"
          :index="category.id.toString()"
          @click="filterByCategory(category.id)"
          :class="{ 'active': selectedCategory === category.id }"
        >
          <span>{{ category.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="file-list-container">
      <el-row :gutter="20" class="file-list" v-if="files.length">
        <el-col :span="6" v-for="file in files" :key="file.uuid">
          <div class="flip-card-wrapper">
            <div class="flip-card" @click="downloadFile(file.uuid, $event)">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img :src="file.fileImg" class="file-image" />
                  <h3 class="file-title">{{ file.fileName }}</h3>
                </div>
                <div class="flip-card-back">
                  <h3>{{ file.fileName }}</h3>
                  <p>{{ file.introduction }}</p>
                  <div class="file-meta">
                    <span>作者: {{ file.authorName }}</span>
                    <span>大小:{{ file.fileSizeDisplay }}</span>
                    <span>创建时间: {{ file.createTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <el-progress
              v-if="file.downloading"
              class="custom-progress"
              :percentage="file.downloadProgress"
              status="active"
              :text-inside="true"
              stroke-width="8"
            />
          </div>
        </el-col>
      </el-row>
      <div v-else class="no-files">
        没有搜索到任何文件哦~
      </div>
      <div class="pagination" v-if="files.length">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalFiles"
          :page-size="pageSize"
          @current-change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      categories: [],
      files: [],
      totalFiles: 0,
      pageSize: 8,
      currentPage: 1,
      selectedCategory: null
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.fetchCategories();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      if (window.innerWidth <= 1200) {
        this.$router.push('/new-home');
      }
    },
    checkLoginStatus() {
    return fetch('https://project.wuyi.life/user/islogin', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    })
    .then(response => response.json())
    .then(data => {
      return data.data;
    })
    .catch(error => {
      console.error('Error checking login status:', error);
      return false;
    });
  },
    fetchCategories() {
      console.log('Fetching categories...');
      if (this.categories.length === 0) {
        fetch('https://project.wuyi.life/file/categories', {
          method: 'GET',
          headers: {
            'token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            console.log('Fetch categories response:', response);
            return response.json();
          })
          .then(data => {
            console.log('Fetch categories data:', data);
            if (data.code === 0) {
              this.categories = data.data;
              if (this.categories.length > 0) {
                this.filterByCategory(this.categories[0].id);
              }
            } else {
              // this.$message.error('获取分类信息失败')
            }
          })
          .catch(error => {
            console.error('Error:', error);
            // this.$message.error('获取分类信息失败')
          });
      }
    },
    fetchFilesByCategory(categoryId, page = 1) {
      console.log('Fetching files by category:', categoryId);
      fetch(`https://project.wuyi.life/file/list?category_id=${categoryId}&page=${page}&page_size=${this.pageSize}`, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log('Fetch files by category data:', data);
          if (data.code === 0) {
            this.files = data.data.files.map(file => {
              const fileSizeInKB = file.fileSize / 1024;
              const fileSizeDisplay = fileSizeInKB > 1024 ? (fileSizeInKB / 1024).toFixed(2) + ' MB' : fileSizeInKB.toFixed(2) + ' KB';
              return { ...file, downloading: false, downloadProgress: 0, fileSizeDisplay };
            });
            this.totalFiles = data.data.total;
          } else {
            // this.$message.error('获取文件列表失败')
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // this.$message.error('获取文件列表失败')
        });
    },
    filterByCategory(categoryId) {
      console.log('Filtering files by category:', categoryId);
      this.selectedCategory = categoryId;
      this.fetchFilesByCategory(categoryId, this.currentPage);
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchFilesByCategory(this.selectedCategory, page);
    },
    downloadFile(uuid) {
    const file = this.files.find(f => f.uuid === uuid);
    if (file) {
      this.checkLoginStatus().then(isLoggedIn => {
        if (!isLoggedIn) {
          this.$router.push('/user/login'); // 跳转到登录页面
          return;
        }
        
        file.downloading = true;
        fetch(`https://project.wuyi.life/file/download?uuid=${uuid}`, {
          method: 'GET',
          headers: {
            'token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
          }
        }).then(response => {
          const reader = response.body.getReader();
          const contentLength = +response.headers.get('Content-Length');
          let receivedLength = 0;
          let chunks = [];

          return reader.read().then(function processText({ done, value }) {
            if (done) {
              const blob = new Blob(chunks);
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = file.fileName;
              link.click();
              file.downloading = false;
              return;
            }

            chunks.push(value);
            receivedLength += value.length;
            file.downloadProgress = Math.round((receivedLength / contentLength) * 100);
            return reader.read().then(processText);
          });
        }).catch(error => {
          console.error('Error:', error);
          file.downloading = false;
          this.$message.error('下载失败');
        });
      });
      }
    }
  }
}
</script>


<style scoped>
.home-page {
  display: flex;
  padding-top: 60px;
  position: relative;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.3; /* 调整透明度 */
  z-index: 2;
}

.el-header::-webkit-scrollbar {
  width: 0;  /* 仅用于隐藏滚动条 */
  background: transparent;  /* 使滚动条背景透明 */
}

.el-header {
  -ms-overflow-style: none;  /* 适用于 IE 和 Edge */
  scrollbar-width: none;  /* 适用于 Firefox */
}

.categories {
  width: 200px;
  background-color: rgba(11, 108, 184, 0.9);  /* 半透明背景色 */
  font-weight: bold;
  height: calc(100vh - 60px); /* 减去导航栏的高度 */
  position: fixed;
  top: 60px; /* 导航栏高度 */
  left: 0;
  z-index: 1; /* 确保其在背景层之上 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.categories .el-menu {
  flex: 1;
  overflow: auto; /* 使菜单项可以滚动 */
}

.categories .el-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px; /* 根据需要调整高度 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.categories .el-menu-item.active {
  background-color: #ffffff !important;
  color: #0B6CB8 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.file-list-container {
  flex: 1;
  padding: 20px;
  margin-left: 200px; /* 根据导航栏的实际宽度调整此值 */
  /* background-color: rgba(240, 242, 245, 0.9);  半透明背景色 */
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* 确保内边距不会影响总宽度 */
  z-index: 999;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 保持卡片靠左对齐 */
  width: 100%;
  min-width: 1100px;
  box-sizing: border-box; /* 确保内边距不会影响总宽度 */
}

.flip-card-wrapper {
  position: relative;
  width: 250px;
  height: 270px; /* 调整高度以容纳进度条 */
  margin: 10px; /* 添加间距以防止重叠 */
  box-sizing: border-box; /* 确保内边距不会影响总宽度 */
  z-index: 999;
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px; /* 3D视图 */
  margin: 10px; /* 添加间距以防止重叠 */
  cursor: pointer;
  border-radius: 50px; /* 设置圆角 */

}


.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
}


.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50px; /* 设置圆角 */
  box-sizing: border-box;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
}

.flip-card-back {
  background-color: #0B6CB8;
  color: white;
  transform: rotateY(180deg);
  padding: 15px;
  white-space: pre-wrap; /* 确保长单词和段落能换行 */
  word-break: break-word; /* 确保长单词能换行 */
}

.file-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.file-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.file-meta {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  /* 调整文字大小 */
  white-space: pre-wrap; /* 确保长单词和段落能换行 */
  word-break: break-word; /* 确保长单词能换行 */
}

.el-progress {
  position: absolute;
  bottom: -20px; /* 调整进度条位置，使其更显眼 */
  left: 0;
  width: 100%;
  z-index: 999;
}

.custom-progress .el-progress-bar__outer {
  background-color: rgba(0, 0, 0, 0.1); /* 设置背景色为浅灰色 */
}

.custom-progress .el-progress-bar__inner {
  background-color: #409eff; /* 设置进度条颜色为蓝色 */
}

.pagination {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  width: calc(100% - 200px); /* 调整导航栏的宽度 */
  left: 200px; /* 左边留出导航栏的宽度 */
  /* background-color: rgba(240, 242, 245, 0.9);  半透明背景色 */
  padding: 10px 0;
  z-index: 999;
}
</style>