<template>
  <div class="auth-container">
    <div class="auth-form-wrapper">
      <h2 class="form-title">登录</h2>
      <el-form :model="loginForm" label-width="80px" class="auth-form">
        <el-form-item label="账号">
          <el-input v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="rememberMe">记住我</el-checkbox>
        </el-form-item>
        <el-form-item class="form-item-center">
          <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
        </el-form-item>
        <el-form-item class="form-item-center">
          <el-link @click="goRegister">没有账号？注册</el-link>
        </el-form-item>
      </el-form>
      <el-button type="default" @click="goHome" class="home-button">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rememberMe: false
    }
  },
  created() {
    this.loadRememberedUser()
  },
  methods: {
    handleLogin() {
      fetch('https://project.wuyi.life/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.loginForm),
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          if (data.code === 0) {
            this.$message.success('登录成功')
            localStorage.setItem('token', data.data.token)
            if (this.rememberMe) {
              localStorage.setItem('rememberedUser', JSON.stringify(this.loginForm))
            } else {
              localStorage.removeItem('rememberedUser')
            }
            this.$router.push('/')
          } else {
            this.$message.error(data.msg || '登录失败')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          this.$message.error('登录失败')
        })
    },
    loadRememberedUser() {
      const rememberedUser = localStorage.getItem('rememberedUser')
      if (rememberedUser) {
        this.loginForm = JSON.parse(rememberedUser)
        this.rememberMe = true
      }
    },
    goRegister() {
      this.$router.push('/user/register')
    },
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('https://wushuang233-1316633667.cos.ap-nanjing.myqcloud.com/share/20240802221444.png') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
}

.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  animation: fadeInUp 0.5s;
}

.auth-form {
  width: 100%;
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-input, .el-button {
  transition: all 0.3s ease;
}

.el-input:focus, .el-button:focus {
  box-shadow: 0 0 10px rgba(81, 203, 238, 1);
  border-color: rgba(81, 203, 238, 1);
}

.login-button {
  background-color: #409eff;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #66b1ff;
  transform: scale(1.05);
}

.form-item-center {
  display: flex;
  justify-content: center;
}

.home-button {
  margin-top: 20px;
  background-color: #67c23a;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #85d97a;
  transform: scale(1.05);
}
</style>
